import { SizeType } from '@components/atoms/button'
import BookFreeClassBtn from '@components/common/book-free-class-btn'
import { FastlyImage } from '@components/common/image'

const IMAGE_PATH = 'images/book-trial-class-jp/'
const COMMON_IMAGE_PATH = 'images/common/'
type InvestorsType = {
  image: string
  designation: string
  name: string
}
const INVESTORS: InvestorsType[] = [
  {
    name: 'Hiro Mizushima',
    designation: 'Investor, Actor',
    image: 'hiro-mizushima',
  },
  {
    name: 'Peter Weck',
    designation: 'Co-Founder, Simply Hired',
    image: 'peter-weck',
  },
  {
    name: 'Jonathan Hendriksen',
    designation: 'Founder, Shuttlerock',
    image: 'jonathan-hendriksen',
  },
]

type Props = {
  isCoursePage?: boolean
  urlQuery?: string
  isCodingCampPage?: boolean
  ctaSize?: SizeType
  shouldScrollToTop?: boolean
}

const Investors = ({
  isCoursePage,
  urlQuery,
  isCodingCampPage,
  ctaSize,
  shouldScrollToTop,
}: Props) => {
  return (
    <div className="relative">
      <div className="absolute">
        {' '}
        {!isCoursePage && (
          <FastlyImage
            src={`${COMMON_IMAGE_PATH}bg-gr.png`}
            alt=""
            width={753}
            height={753}
          />
        )}
      </div>
      <div className="container mx-auto">
        <div
          className={`flex flex-col py-10 relative lg:my-10 rounded-2xl ${
            !isCoursePage && 'items-center md:px-4 md:mx-4'
          }`}
        >
          <h2
            className={`text-grey font-600 ${
              isCoursePage
                ? 'text-2xl text-left'
                : 'px-4 text-center text-2xl md:text-4xl'
            }`}
          >
            Codingal is backed by{' '}
            <span className="underlined-text">esteemed investors</span>
          </h2>
          <p
            className={`text-lg pt-3 ${
              isCoursePage ? 'text-left' : 'text-center px-4'
            }`}
          >
            Codingal has secured substantial backing from renowned investment
            firms and a host of prominent angel investors
          </p>
          <div
            className={` mt-5 w-full md:w-auto  ${
              isCoursePage
                ? ''
                : 'bg-white shadow-md border-grey-600 rounded-2xl md:border'
            }`}
          >
            <div
              className={`flex flex-wrap mb-3 lg:mb-5 pb-5 w-full justify-center ${
                isCoursePage
                  ? ''
                  : 'mt-3 md:py-10 p-2 md:p-4 md:px-20 md:w-auto'
              }`}
            >
              {INVESTORS.map((investor, index) => {
                return (
                  <div
                    className={`${
                      isCoursePage ? 'w-auto p-1' : 'w-1/2 md:w-1/4 p-1 md:p-2'
                    }`}
                    key={index}
                  >
                    <div className="h-full text-center border-grey-600 rounded-xl border">
                      <div className="p-2 pb-1 md:p-3">
                        <FastlyImage
                          src={`${IMAGE_PATH}${investor.image}.png`}
                          webpSrc={`${IMAGE_PATH}${investor.image}.webp`}
                          alt={investor.name}
                          width={181}
                          height={173}
                        />
                      </div>
                      <p className="font-600 md:text-xl md:px-2">
                        {investor.name}
                      </p>
                      <p className="text-sm px-3 pb-3">
                        {investor.designation}
                      </p>
                    </div>
                  </div>
                )
              })}
            </div>
            <div
              className={`flex flex-wrap justify-center md:gap-2 my-3 md:pt-0 w-full md:w-auto ${
                isCoursePage ? '' : 'p-4 md:p-10'
              }`}
            >
              <div className="max-w-2/6">
                <FastlyImage
                  src={`${IMAGE_PATH}rb.png`}
                  webpSrc={`${IMAGE_PATH}rb.webp`}
                  alt=""
                  width={225}
                  height={94}
                />
              </div>
              <div className="max-w-2/6">
                {' '}
                <FastlyImage
                  src={`${IMAGE_PATH}ms.png`}
                  webpSrc={`${IMAGE_PATH}ms.webp`}
                  alt=""
                  width={225}
                  height={94}
                />
              </div>
              <div className="max-w-2/6">
                {' '}
                <FastlyImage
                  src={`${IMAGE_PATH}yc.png`}
                  webpSrc={`${IMAGE_PATH}yc.webp`}
                  alt=""
                  width={225}
                  height={94}
                />
              </div>
            </div>
          </div>
          {!isCodingCampPage && (
            <>
              <div className="container mx-auto text-grey-800 flex flex-col justify-center lg:flex-row space-y-6 lg:space-y-0 lg:space-x-24 py-12 px-5">
                <div className="text-center">
                  <p className="mb-2">Built by alumni of</p>
                  <div className="flex gap-x-12 items-center justify-center">
                    <FastlyImage
                      src={`${COMMON_IMAGE_PATH}/partners/google-medium.png`}
                      webpSrc={`${COMMON_IMAGE_PATH}/partners/google-medium.webp`}
                      height={41}
                      width={120}
                      alt="Google logo"
                    />
                    <FastlyImage
                      src={`${COMMON_IMAGE_PATH}/partners/amazon.png`}
                      webpSrc={`${COMMON_IMAGE_PATH}/partners/amazon.webp`}
                      height={39}
                      width={128}
                      alt="Google logo"
                    />
                    <FastlyImage
                      src={`${COMMON_IMAGE_PATH}/partners/iit-roorkee-text.png`}
                      webpSrc={`${COMMON_IMAGE_PATH}/partners/iit-roorkee-text.webp`}
                      height={59}
                      width={149}
                      alt="IIT Roorkee"
                    />
                  </div>
                </div>

                <div className="text-center">
                  <p className="mb-2">Accredited by</p>
                  <div className="relative h-16 w-40 mx-auto">
                    <FastlyImage
                      src={`${COMMON_IMAGE_PATH}/partners/stem-bg-white.png`}
                      webpSrc={`${COMMON_IMAGE_PATH}/partners/stem-bg-white.webp`}
                      alt="stem logo"
                      layout="fill"
                    />
                  </div>
                </div>
              </div>
              {!isCoursePage && (
                <div className="w-full flex justify-center px-4">
                  <BookFreeClassBtn
                    className="w-full"
                    shouldScrollToTop={shouldScrollToTop}
                    ctaText="Try a free lesson now"
                    size={ctaSize}
                    query={urlQuery}
                  />
                </div>
              )}
              {isCoursePage && (
                <div className="mx-auto w-full md:max-w-xs text-center">
                  <BookFreeClassBtn query={urlQuery} size={ctaSize} />
                </div>
              )}
            </>
          )}
          {isCodingCampPage && (
            <div className="flex justify-center pt-16">
              {' '}
              <a
                className="btn btn-orange w-full md:w-auto py-3 text-lg"
                href="#courses"
              >
                Claim your spot now
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Investors
